//
// your custom scss goes here
//

.fw-black {
  font-weight: 900 !important;
}

.rounded-lg {
  border-radius: 2rem;
}

.carousel {
  .tns-controls button {
    background: white !important;
    opacity: 1 !important;
  }
  .tns-controls button:hover {
    background: $gray-100 !important;
    opacity: 1 !important;
  }
  .tns-controls button i {
    color: black !important;
  }

  .tns-nav button::before {
    background: white !important;
  }

  .tns-nav button::before {
    background: white !important;
  }

  .tns-nav button.tns-nav-active::before {
    background: gray !important;
  }
}

@media only screen and (max-width: 1024px) {
  .tns-controls button {
    display: none !important;
  }
  // nav: highlight active page
  .nav-minimal .nav-link.underline {
    padding: 0;
  }
  .nav-link.underline.active::before {
    width: 100%;
  }
}
